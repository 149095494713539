import { render, staticRenderFns } from "./Camp.vue?vue&type=template&id=cddc5434&scoped=true&"
import script from "./Camp.vue?vue&type=script&lang=js&"
export * from "./Camp.vue?vue&type=script&lang=js&"
import style0 from "./Camp.vue?vue&type=style&index=0&id=cddc5434&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cddc5434",
  null
  
)

export default component.exports