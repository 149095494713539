<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="py-4 breadcrumb-bg"
      style="
        background-image: url(/assets/img/background/page-title-bg-img6.jpg);
      "
    >
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">活動實錄</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item">
                <router-link class="text-white" to="/activity">活動實錄</router-link>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                營隊
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-8 py-md-10">
      <div class="container">
        <div class="row" v-if="events.length === 0">
          <h2 class="w-100 text-center text-danger">目前尚無相關活動</h2>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4" v-for="item in events" :key="item.id" style="margin-bottom: 1.56rem;">
            <router-link :to="`/record/${ item.id }`" class="card" style="height: 100%; margin-bottom: 0;">
              <div class="position-relative">
                <img class="card-img-top adjust-height" :src="item.banner" alt="Card image" />
                <div class="card-img-overlay p-0">
                  <span class="badge badge-rounded m-4" :class="`badge-${item.themeClass}`">
                    {{ item.month }} / {{ item.date }}
                  </span>
                </div>
              </div>
              <div :class="`border-${item.themeClass}`"
                class="card-body border-top-5 px-3 rounded-bottom"
              >
                <h3 class="card-title">
                  <router-link :to="`/record/${ item.id }`" :class="`text-${item.themeClass}`" class="text-capitalize d-block text-truncate">
                    {{ item.title }}
                  </router-link>
                </h3>
                <ul class="list-unstyled d-flex mb-1 text-muted">
                  <li class="mr-4">
                      <i class="fa fa-map-marker mr-2" aria-hidden="true"></i
                      >{{ item.place }}
                  </li>
                  <li class="mr-2">
                      <i class="fa fa-camera-retro mr-2" aria-hidden="true"></i
                      >{{ item.img_count }} Pics
                  </li>
                </ul>
                <p class="mb-2">{{ item.content }}</p>
                <router-link :to="`/record/${ item.id }`" class="btn btn-link text-hover-pink pl-0">
                  <i class="fa fa-angle-double-right mr-1" aria-hidden="true"></i>
                  Read More
                </router-link>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
// import dayjs from 'dayjs'
export default {
  name: 'camp',
  data () {
    return {
      events: []
    }
  },
  created () {
    const vm = this
    vm.getData()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getData () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/event/list`

      vm.axios.post(api, {
        token: tokenGenerator(),
        type: ['2'],
        categoryId: ['1'],
        pageSize: 10,
        page: 1
      })
        .then((res) => {
          vm.events = res.data.content
          vm.events.forEach((item, index) => {
            const [yymmdd] = item.eventDate.split(' ')
            const [yy, mm, dd] = yymmdd.split('-')
            vm.$set(item, 'year', yy)
            vm.$set(item, 'month', mm)
            vm.$set(item, 'date', dd)

            const i = index + 1
            if (i % 6 === 1) {
              return (item.themeClass = 'warning')
            } else if (i % 6 === 2) {
              return (item.themeClass = 'success')
            } else if (i % 6 === 3) {
              return (item.themeClass = 'danger')
            } else if (i % 6 === 4) {
              return (item.themeClass = 'info')
            } else if (i % 6 === 5) {
              return (item.themeClass = 'purple')
            } else {
              return (item.themeClass = 'pink')
            }
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.adjust-height {
  height: 220px;
  object-fit: cover;
}
</style>
